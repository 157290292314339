$(document).ready(function(){

	$(window).scroll(function(){

		var scroll = $(window).scrollTop();
		var alto = $(window).height();
		var menu = document.getElementById('menu');
		var cta = document.getElementById('cta');
		var menu_lang = document.getElementById('circularMenu1');

		var boxCounter = document.getElementById('boxCounterServices');
		if (typeof boxCounter !== 'undefined' && boxCounter !== null) {
			var boxCounter_p = positionTop(boxCounter);
		}

		var counterAliados = document.getElementById('counterAliados');
		if (typeof counterAliados !== 'undefined' && counterAliados !== null) {
			var counterAliados_p = positionTop(counterAliados);
		}

		if ( scroll >= (alto - 350) ) {
			menu.classList.add('active');
			cta.classList.add('active');
			menu_lang.classList.add('top');
		}else{
			menu.classList.remove('active');
			cta.classList.remove('active');
			menu_lang.classList.remove('top');
		}

		if ( (boxCounter_p - alto) >= scroll ) {
			boxCounter.classList.remove('active');
		}else{
			boxCounter.classList.add('active');
		}

		if (typeof counterAliados_p !== 'undefined' && counterAliados_p !== null) {
			if ( (counterAliados_p - alto) <= scroll ) {
				counterAliados.getElementsByTagName('div')[0].classList.add('animate__animated');
				counterAliados.getElementsByTagName('div')[1].classList.add('animate__animated');
				counterAliados.getElementsByTagName('div')[2].classList.add('animate__animated');

				counterAliados.getElementsByTagName('div')[0].classList.add('animate__fadeInUp');
				counterAliados.getElementsByTagName('div')[1].classList.add('animate__fadeInUp');
				counterAliados.getElementsByTagName('div')[2].classList.add('animate__fadeInUp');

				counterAliados.getElementsByTagName('div')[1].classList.add('animate__delay-1s');
				counterAliados.getElementsByTagName('div')[2].classList.add('animate__delay-2s');
			}else{
				counterAliados.getElementsByTagName('div')[0].classList.remove('animate__animated');
				counterAliados.getElementsByTagName('div')[1].classList.remove('animate__animated');
				counterAliados.getElementsByTagName('div')[2].classList.remove('animate__animated');

				counterAliados.getElementsByTagName('div')[0].classList.remove('animate__fadeInUp');
				counterAliados.getElementsByTagName('div')[1].classList.remove('animate__fadeInUp');
				counterAliados.getElementsByTagName('div')[2].classList.remove('animate__fadeInUp');

				counterAliados.getElementsByTagName('div')[1].classList.remove('animate__delay-1s');
				counterAliados.getElementsByTagName('div')[2].classList.remove('animate__delay-2s');
			}
		}

	})

	function positionTop(elem) {
		var top = 0;

		do {
			top += elem.offsetTop-elem.scrollTop;
		} while ( elem = elem.offsetParent );

		return top;
	}

	// $("#carousel_servicios").swipe({
	// 	swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
	// 		if (direction == 'left') $(this).Carousel('next');
	// 		if (direction == 'right') $(this).Carousel('prev');
	// 	},
	// 	allowPageScroll: "vertical"
	// });

});
