import PureCounter from "@srexi/purecounterjs";
window.pure = new PureCounter();

new PureCounter({
	// Setting that can't' be overriden on pre-element
	selector: '.purecounter',		// HTML query selector for spesific element

	// Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
	start: 0, 			            // Starting number [unit]
	end: 100, 			            // End number [unit]
	duration: 2, 	                // The time in seconds for the animation to complete [seconds]
	delay: 10, 			            // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
	once: true, 		            // Counting at once or recount when the element in view [boolean]
	repeat: false, 		            // Repeat count for certain time [boolean:false|seconds]
	decimals: 0, 		            // How many decimal places to show. [unit]
	legacy: true,                   // If this is true it will use the scroll event listener on browsers
	filesizing: false, 	            // This will enable/disable File Size format [boolean]
	currency: false, 	            // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
	separator: true, 	            // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
});
