require('./bootstrap');

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');

require('bootstrap');
require('./js_web');

// window.simpleParallax = require('simple-parallax-js');s
require('./PureCounter');

// require('gsap');
window.gssap = require('./gsap');

window.Swal = require('sweetalert2');

window.Splide = require('@splidejs/splide');

